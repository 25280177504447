<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'actions.change_password' | translate }}
    v-card-content
      v-password-field.mb-3(
        :label="$t('base.new_password')"
        v-model="form.password")
      v-password-field(
        :label="$t('base.repeat_password')"
        v-model="form.repeat_password")
    v-card-actions
      v-btn(
        :disabled="!isPasswordValid"
        :loading="loading"
        @click="onUpdate") {{ 'actions.save' | translate }}
</template>

<script>
  export default {
    name: 'ChangePasswordModal',

    props: {
      item: Object
    },

    data: () => ({
      form: {
        password: '',
        repeat_password: ''
      },
      loading: false
    }),

    computed: {
      isPasswordValid () {
        return this.form.password.length >= 6 &&
          this.form.password === this.form.repeat_password
      }
    },

    methods: {
      async onUpdate () {
        this.loading = true
        const payload = {
          password: this.form.password
        }
        await this.$store.dispatch('updateUser', { id: this.item.id, payload })
        this.loading = false
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
