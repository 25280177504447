<template lang="pug">
  .employees-wrapper
    .employees-table
      v-card
        .employees-table__header
          .employees-table__row.employees-table__row--header
            .employees-table__column {{ 'base.first_name' | translate }}
            .employees-table__column {{ 'base.email' | translate }}
            .employees-table__column {{ 'base.role' | translate }}
      v-table-body(
        v-if="!loading"
        outside-table
        gap
        :empty="!users.length")

        employees-table-row(
          v-for="item in users"
          :key="item.id"
          :item="item")

      employees-table-row-skeleton(
        v-else
        :count="6")

      .employees-table__footer(
        v-if="!loading && showPagination")
        v-paginate(
          v-model="page"
          :page-count="pageCount"
          :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'

import EmployeesTableRow from './EmployeesTableRow'
import EmployeesTableRowSkeleton from './EmployeesTableRowSkeleton'

export default {
  name: 'EmployeesTable',

  mixins: [paginationMixin],

  components: {
    EmployeesTableRow,
    EmployeesTableRowSkeleton
  },

  mounted () {
    this.setupPagination(this.fetchUsers)
  },

  methods: {
    ...mapActions(['fetchUsers']),
    async loadData () {
      this.loading = true
      const params = {
        page: this.page,
        ...(this.filters || {})
      }
      const { data, meta } = await this.fetchUsers(params)
      this.items = data
      this.pageCount = Math.floor(meta.total / meta.per_page) + (meta.total % meta.per_page > 0 ? 1 : 0)
      this.loading = false
    }
  },

  computed: {
    ...mapGetters([
      'users'
    ])
  }
}
</script>

<style lang="scss">
@import '@/sass/abstracts/_variables';

.employees-table {
  font-size: 1.6rem;
  line-height: 2rem;

  &__row {

    &--header {
      display: flex;
      align-items: center;
      padding: 1.5rem 2.5rem;
      color: $color-gray;
    }

    &-head {
      position: relative;
      padding: 1.2rem 2.5rem;
      display: flex;
      align-items: center;
    }

    &-body {
      border-top: .6rem solid $color-gray-light-2;
    }
  }

  &__header {
    margin-bottom: 1.5rem;
  }

  &__column {
    display: flex;
    align-items: center;
    width: 33.3%;
  }

  &__button {
    margin-left: auto;
  }

  &__toggle {
    margin-left: auto;
    padding: 1rem;
    position: relative;
    width: 1.2rem;
    height: .8rem;
    cursor: pointer;
    user-select: none;

    &::after {
      position: absolute;
      content: '';
      width: 1.2rem;
      height: .8rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      background: url('../../../../../assets/img/icons/arrow_down.svg') no-repeat center top;
      background-size: 100%;
      transition: all .4s ease;
    }

    &--active {
      &::after {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  &__footer {
    padding-top: 3rem;
    height: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
