<template lang="pug">
  v-card
    .employees-table__row
      .employees-table__row-head
        .employees-table__column {{ item.full_name }}
        .employees-table__column {{ item.email }}
        .employees-table__column {{ item.role | groupName | translate}}
          v-btn.employees-table__toggle(
            icon
            small
            v-permission-hide="permissions.update_user"
            @click="toggled = !toggled"
            :class="{ 'employees-table__toggle--active': toggled }")
      .employees-table__row-body(v-if="toggled")
        v-card-content
          .cols-x.mb-3
            .col-x-3
              v-select(
                :label="$t('base.user_role')"
                :options="types"
                item-value="value"
                v-model="form.user_type")
            .col-x-3
              v-text-field(
                :label="$t('base.first_name')"
                v-model="form.name")
            .col-x-3
              v-text-field(
                :label="$t('base.last_name')"
                v-model="form.surname")
          .cols-x.mb-3
            .col-x-3
              v-text-field(
                :label="$t('base.phone')"
                v-model="form.phone")
            .col-x-3
              v-text-field(
                :label="$t('base.email')"
                v-model="form.email")
            .col-x-3
              v-btn(
                outline
                accent
                @click="modal.showChangePassword = true")
                | {{ 'actions.change_password' | translate }}
          v-btn(
            :loading="loading"
            @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
          delete-btn(
            v-if="item.id !== user.user.id"
            v-permission-hide="permissions.delete_user"
            @delete="onDelete")
      v-dialog(
        max-width="280"
        v-model="modal.showChangePassword")
        change-password-modal(
          :item="item"
          @close="modal.showChangePassword = false")
</template>

<script>
import { mapGetters } from 'vuex'

import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'
import ChangePasswordModal from './modals/ChangePasswordModal'

import { USER_TYPES } from '@/util/consts.js'
import permissions from '@/util/permissions'

export default {
  name: 'EmployeesTableRow',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  components: {
    ChangePasswordModal,
    DeleteBtn
  },

  data: () => ({
    form: {
      user_type: null,
      name: '',
      surname: '',
      phone: '',
      email: '',
      password: null
    },
    modal: {
      showChangePassword: false
    },
    toggled: false,
    types: Object.entries(USER_TYPES)
      .map(item => ({
        value: item[0],
        name: item[1]
      })),
    loading: false,
    permissions: permissions
  }),

  mounted () {
    this.form.user_type = this.item.role
    this.form.name = this.item.first_name
    this.form.surname = this.item.last_name
    this.form.phone = this.item.phone
    this.form.email = this.item.email
  },

  filters: {
    groupName (value) {
      return USER_TYPES[value]
    }
  },

  methods: {
    async onUpdate () {
      this.loading = true
      const payload = {
        email: this.form.email,
        first_name: this.form.name,
        last_name: this.form.surname,
        role: this.form.user_type,
        phone: this.form.phone
      }
      if (this.form.password) {
        payload.password = this.form.password
      }
      await this.$store.dispatch('updateUser', { id: this.item.id, payload })
      this.loading = false
      this.$emit('close')
    },

    async onDelete () {
      await this.$store.dispatch('deleteUser', { id: this.item.id })
      this.$emit('close')
    }
  },

  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
</style>
